import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchTasks(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('https://backendapinodejs.timecheckit.com/api/todos?user=' + JSON.parse(localStorage.getItem("userData"))._id, { params: payload })
          .then((response) => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    addTask(ctx, taskData) {
      return new Promise((resolve, reject) => {
        if (!taskData.assignee) taskData.assignee = []
        else {
          var arrayTemp = []
          taskData.assignee.forEach(element => {
            arrayTemp.push(
              element.id
            )
          });
        }
        taskData.assignee = arrayTemp
        taskData.user = JSON.parse(localStorage.getItem("userData"))._id
        axios
          .post('https://backendapinodejs.timecheckit.com/api/todos', taskData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateTask(ctx, taskData) {
      console.log("taks",taskData)
      var idToUpdate = taskData._id;
      delete taskData._id;
      if (taskData.assignee) {
        var tempAssignee = []
        taskData.assignee.forEach(element => {
          tempAssignee.push(element._id)
        });
      }
      if (taskData.user) {
        taskData.user = taskData.user._id
      }
      if (taskData.date) {
        taskData.startDate = taskData.date.startDate
        taskData.endDate = taskData.date.endDate
      }
      if (taskData.tags) {
        var tempTags = []
        taskData.tags.forEach(element => {
          tempTags.push(element._id)
        });
      }
      taskData.tags = tempTags
      taskData.assignee = tempAssignee
      console.log(taskData)
      return new Promise((resolve, reject) => {
        axios
          .put('https://backendapinodejs.timecheckit.com/api/todos/' + idToUpdate,  taskData )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeTask(ctx, taskData) {
      console.log(taskData);
      return new Promise((resolve, reject) => {
        axios
          .delete('https://backendapinodejs.timecheckit.com/api/todos/' + taskData._id)
          .then(response => resolve(response))
          .catch (error => reject(error))
      })
     
    },
  },
}
